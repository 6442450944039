export type RetailersMap = {
  getBounds: () => google.maps.LatLngBounds;
  getCenter: () => google.maps.LatLng | undefined;
  setCenter: (latlng: google.maps.LatLng | google.maps.LatLngLiteral) => void;
  getRadius: () => number;
  getMapInstance: () => google.maps.Map;
  setBounds: (bounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral) => void;
  zoomOut: (a: number) => void;
  onBoundsChanged: () => void;
};

type RetailerLocation = {
  address: string;
  distance_in_kms: string;
  latitude: number;
  longitude: number;
  marker: google.maps.Marker;
  name: string;
  phone_number: string;
  website: string;
  [key: string]: google.maps.Marker | number | string | undefined;
};

export type RetailersList = {
  getList: () => RetailerLocation[];
  refresh: () => void;
  render: ($target: any) => void;
  onRefreshed: (func: any) => void;
  onClickRetailer: (func: any) => void;
};

export default function () {
  let shippingTo: string;
  let retailers: RetailerLocation[] = [];
  let retailerMapMarkers: (google.maps.Marker | null)[] = [];
  let retailersMap: RetailersMap;
  let onRefreshedCallback: (a: RetailerLocation[]) => void = console.info;
  let onClickRetailerCallback: (
    r: RetailerLocation,
    event: any,
    source: string,
    marker: google.maps.Marker,
    retailerMapMarkers: (google.maps.Marker | null)[],
  ) => void = console.info;
  let template = '|NAME|';

  async function getRetailersByLatLng(
    latlng: google.maps.LatLng,
    radius: number,
  ): Promise<RetailerLocation[]> {
    const configuration = JSON.parse(
      document.querySelector('script[rel="configuration"]')!.innerHTML,
    );
    const fallbackUrl = 'https://production.retailers.boobook-services.com';
    const URL_API_RETAILERS = configuration
      ? configuration.applications.retailers.origin
      : fallbackUrl;
    const headers = {
      Accept: 'application/json',
    };

    let url = `${URL_API_RETAILERS}/retailers/nearby?latitude=${latlng.lat()}&longitude=${latlng.lng()}&radius=${radius}`;

    let response: Response;
    try {
      response = await fetch(url, { headers });
    } catch {
      console.error('Could not access retailers API');
      return [];
    }
    const data = await response.json();
    if (typeof data.retailers === 'undefined') {
      return [];
    }
    return data.retailers;
  }

  function getList() {
    return retailers;
  }

  async function refresh() {
    if (!retailersMap) {
      return;
    }

    const mapBounds = retailersMap.getBounds();
    const mapCenter = retailersMap.getCenter();
    const mapRadius = retailersMap.getRadius();

    if (!mapCenter) {
      throw new Error('Could not find map center');
    }

    const retailerLocations = await getRetailersByLatLng(mapCenter, mapRadius);
    retailers = retailerLocations.filter((r) =>
      mapBounds.contains(new google.maps.LatLng(r.latitude, r.longitude)),
    );
    onRefreshedCallback(retailers);
  }

  function onRefreshed(func: (a: RetailerLocation[]) => void) {
    onRefreshedCallback = func;
  }

  function onClickRetailer(
    func: (
      r: RetailerLocation,
      event: any,
      source: string,
      marker: google.maps.Marker,
      retailerMapMarkers: (google.maps.Marker | null)[],
    ) => void,
  ) {
    onClickRetailerCallback = func;
  }

  function stripHtml(str: string): string {
    const $tmp = document.createElement('div');
    $tmp.innerHTML = str;
    return $tmp.textContent || $tmp.innerText || '';
  }

  function render($target: HTMLElement) {
    // clear
    $target.innerHTML = '';
    for (let i = 0; i < retailerMapMarkers.length; i++) {
      retailerMapMarkers[i]?.setMap(null);
      retailerMapMarkers[i] = null;
    }
    retailerMapMarkers = [];

    for (let i = 0; i < retailers.length; i++) {
      const r = retailers[i];
      if (!r) {
        continue;
      }

      if (r.address) {
        r.address = r.address.toLowerCase();
      }

      let directions_url: string;
      if (r.address) {
        directions_url = `https://www.google.com/maps/dir//${encodeURIComponent(stripHtml(r.address).replaceAll(',', ' ').replace('  ', ' '))}`;
      } else {
        directions_url = `https://www.google.com/maps/dir//${r.latitude},${r.longitude}`;
      }
      let [distance_number, _, metric] = r.distance_in_kms ? r.distance_in_kms.split(/(\s+)/) : '';
      const interpolate: { [key: string]: string | null } = {
        name: r.name.toLowerCase(),
        distance:
          distance_number && r.distance_in_kms
            ? `${parseFloat(distance_number).toFixed(2)} ${metric} away`
            : '',
        phonenumber: r.phone_number,
        address: r.address,
        directions_url: directions_url,
        website_url: r.website,
      };

      let contents = template;
      for (const key in interpolate) {
        contents = contents.replaceAll(`${key.toUpperCase()}`, interpolate[key] || '');
      }
      const $r = document.createElement('div');
      $r.className = 'RetailerCard';
      $r.setAttribute('id', `Retailer${r.id}`);
      $r.innerHTML = contents;

      if (!r.phonenumber) {
        const $pn = $r.querySelector('.RetailerCard_PhoneNumber');
        $pn?.parentElement?.removeChild($pn);
        const $pnb = $r.querySelector('.RetailerCard_Phone');
        $pnb?.parentElement?.removeChild($pnb);
      }

      if (!r.website) {
        const $ws = $r.querySelector('.RetailerCard_Website');
        $ws?.parentElement?.removeChild($ws);
      }

      if (!directions_url) {
        const $du = $r.querySelector('.RetailerCard_Directions');
        $du?.parentElement?.removeChild($du);
      }

      $target.appendChild($r);

      // map marker
      const marker = new google.maps.Marker({
        title: r.name,
        position: new google.maps.LatLng(r.latitude, r.longitude),
        icon: {
          path: 'M11,0C4.8,0,0,5.2,0,11.3c0,2.4,0.8,4.7,2,6.6l4.5,6.2l3.9,5.6c0.3,0.4,0.9,0.4,1.2,0l3.9-5.5L20,18c1.1-2,1.9-4.4,1.9-6.8 C22,5.1,17,0,11,0z M17,11.5c-0.2,2.8-2.5,5-5.4,5.4c-3.8,0.4-7-2.8-6.6-6.6C5,10.2,5,10.1,5,10C5.4,7.1,7.9,4.8,11,4.8 c2.3,0,4.4,1.3,5.4,3.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.1,0.1,0.2C16.9,9.4,17.1,10.4,17,11.5z',
          anchor: new google.maps.Point(15, 30),
          fillColor: '#000000',
          fillOpacity: 1,
          scale: 1,
          strokeWeight: 0,
        },
      });

      // events
      marker.addListener('click', (event: any) => {
        onClickRetailerCallback(r, event, 'marker', marker, retailerMapMarkers);
      });

      $r.addEventListener('click', (event: any) => {
        onClickRetailerCallback(r, event, 'list', marker, retailerMapMarkers);
      });

      // append
      r.marker = marker;
      marker.setMap(retailersMap.getMapInstance());
      retailerMapMarkers.push(marker);
    }
  }

  function init(retailersMap_: RetailersMap, template_: string): RetailersList {
    retailersMap = retailersMap_;
    template = template_;

    const configuration = JSON.parse(
      document.querySelector('script[rel="configuration"]')!.innerHTML,
    );
    shippingTo = configuration.commerce_information.shipping_to || 'AU';

    return {
      getList,
      refresh,
      render,
      onRefreshed,
      onClickRetailer,
    };
  }

  window.RetailersList = init;
  window.onRetailersListReady();
}
