declare const _st: any;

export default function () {
  initializeSwiftTypeSearch();
  initializeInputValuesAndLoadResultsIfNeeded();
  function initializeInputValuesAndLoadResultsIfNeeded() {
    const searchParams = new URLSearchParams(document.location.search);
    const searchValue = (searchParams.get('q') || '').split(/#/)[0];
    if (searchValue && searchParams.get('q') !== undefined && searchParams.get('q') != '') {
      const searchInput: HTMLInputElement | null = document.querySelector(
        '.searchOverlay input[type=text]',
      );
      if (searchInput) {
        searchInput.value = searchValue;
      }
      waitForAnalytics(function () {
        window.Segment.push('Products Searched', {
          query: searchValue,
        });
      });
      loadStyles(searchValue);
      loadSwiftTypeResults(searchValue);
    }
  }

  function waitForAnalytics(callback: () => void) {
    if (window.Segment && window.analytics) {
      callback();
    } else {
      setTimeout(function () {
        waitForAnalytics(callback);
      }, 100);
    }
  }

  function getProductsEndpoint(configuration: any) {
    const productsConfig = configuration?.applications?.['product-detail']?.sources?.products;

    if (productsConfig && typeof productsConfig === 'object') {
      return `${productsConfig['host']}/v2/products`;
    } else {
      return '/api/v1/products';
    }
  }

  function loadStyles(searchValue: string) {
    const results = document.getElementById('results');
    if (results) {
      results.innerHTML = '';
    }
    const siteConfiguration = fetchSiteConfiguration();
    const currencyIdentifier: string = siteConfiguration.commerce_information?.currency_identifier;
    const priceRoleIdentifier: string =
      siteConfiguration.commerce_information?.price_list_identifier;

    if (!currencyIdentifier || !priceRoleIdentifier || searchValue === '') {
      throw new Error('There was a problem configuring the search');
    }

    const apiEndpoint = getProductsEndpoint(siteConfiguration);
    const searchParams = new URLSearchParams({
      currency_identifier: currencyIdentifier,
      channel: 'bellroy.com',
      price_role_identifier: priceRoleIdentifier,
      'filter[search][term]': searchValue,
    }).toString();
    const url: string = `${apiEndpoint}?${searchParams.toString()}`;
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        const returnedJSON = JSON.parse(this.responseText);

        // Handle both v1 and v2 data payloads
        const searchResults = returnedJSON.products || returnedJSON.data;
        if (Array.isArray(searchResults)) {
          loadSearchResults(searchResults);
        }
      } else if (this.readyState == 4 && this.status != 200) {
        console.log('There was a problem loading the styles');
      }
    };
    xmlhttp.open('GET', url, true);
    xmlhttp.send();
  }
  function loadSearchResults(searchResults: any[]) {
    const stylesArray = Array.from(
      new Set(searchResults.map((searchResult) => searchResult.attributes.dimensions.style[0])),
    );
    if (stylesArray.length == 0) {
      showSwiftTypeResults();
    } else {
      const searchParams = new URLSearchParams({
        context: JSON.stringify({ styles: stylesArray.join(',') }),
      });
      const url: string = `/cms/rendered_content_blocks/tech-team-search-results?${searchParams.toString()}`;
      const prodReq = new XMLHttpRequest();
      prodReq.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          const html = JSON.parse(this.responseText).html;
          const newEl = document.createElement('div');
          newEl.innerHTML = html;
          const resultNode: Node | null = document.getElementById('results');
          if (!resultNode) {
            throw new Error("Element with ID 'result' not found");
          }
          resultNode.appendChild(newEl);
          showSwiftTypeResults();
        } else if (this.readyState == 4 && this.status != 200) {
          console.log('There was a problem loading the search results');
        }
      };
      prodReq.open('GET', url, true);
      prodReq.send();
    }
  }

  function loadSwiftTypeResults(searchValue: string) {
    const url = new URL(window.location.href);
    url.searchParams.set('q', searchValue);
    url.hash = '#stq=' + searchValue;
    window.location.href = url.href;
  }

  function showSwiftTypeResults() {
    const stResultsElement = document.getElementById('stResults');

    if (!stResultsElement) {
      throw new Error("Element with ID 'stResults' not found");
    }
    stResultsElement.style.display = 'block';
  }

  function fetchSiteConfiguration() {
    const siteConfiguration = document.querySelector('[rel="configuration"]');
    if (siteConfiguration) {
      try {
        return JSON.parse(siteConfiguration.innerHTML.trim());
      } catch (e) {}
    }
    return {};
  }

  function initializeSwiftTypeSearch() {
    const name = '_st';
    window['SwiftypeObject'] = name;
    window[name] =
      window[name] ||
      function () {
        (window[name].q = window[name].q || []).push(arguments);
      };
    const script = document.createElement('script');
    const firstScriptTag: Node | undefined = document.getElementsByTagName('script')[0];
    if (!firstScriptTag) {
      throw new Error('Could not find script tag');
    }
    script.async = true;
    script.src = '//s.swiftypecdn.com/install/v2/st.js';
    firstScriptTag.parentNode!.insertBefore(script, firstScriptTag);

    _st('install', 'bW-KTZAYtRTqcUrtL5-A', '2.0.0');
  }
}
