interface SubscribeData {
  g: string;
  $fields: string;
  $list_fields: string;
  email: string;
  $source: string;
  $method_type: string;
  $method_id: string;
  $consent_version: string;
  sign_up_source: string;
}

export default function () {
  let i;
  let $form;
  const corpSalesEl = document.getElementById('corp-sales-subscribe');
  if (corpSalesEl) {
    $form = corpSalesEl;
  } else {
    throw new Error('Could not find `corp-sales-subscribe` form element');
  }
  const $formRequiredInputs = $form.querySelectorAll('[required]');
  const $modal = document.getElementById('CorpSalesSubscribeModal');
  if (!$modal) {
    throw new Error('Could not find `CorpSalesSubscribeModal` modal element');
  }
  const $modalCloseEls = $modal.querySelectorAll('.close-modal');

  const closeModal = function (event) {
    event.preventDefault();
    $modal.classList.remove('active');
  };

  for (i = 0; i < $modalCloseEls.length; i += 1) {
    const closeEl = $modalCloseEls[i];
    if (closeEl) {
      closeEl.addEventListener('click', closeModal);
    }
  }

  for (i = 0; i < $formRequiredInputs.length; i += 1) {
    $formRequiredInputs[i].addEventListener('blur', function (event) {
      event.target.classList.add('touched');
    });
  }

  const countriesList = $form.querySelector('select[name="country"]');
  const countryCode = '{{ country_code }}';
  const countryCodeIndex = [...countriesList.options].findIndex((o) => o.value == countryCode);
  countriesList.selectedIndex = Math.max(countryCodeIndex, 0);

  const countriesThatRequireMarketingConsent = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'EL',
    'GR',
    'ES',
    'FI',
    'FR',
    'HR',
    'HU',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK',
    'GB',
    'UK',
    'IS',
    'LI',
    'CA',
  ];
  const isMarketingConsentRequiredIndex =
    countriesThatRequireMarketingConsent.findIndex((c) => c == countryCode) >= 0;
  const subscribeTick = $form.querySelector('input[name="newsletterSubscribe"]');
  subscribeTick.checked = !isMarketingConsentRequiredIndex;

  $form.addEventListener('submit', function (event) {
    event.preventDefault();
    const url = '/corporate_enquiries';
    const http = new XMLHttpRequest();
    const filledInFormData = new FormData($form);

    const blank = $form.querySelector('input[name="leave_this_blank"]').value;
    const csrfTokenEl = document.head.querySelector('meta[name="csrf-token"]');
    let csrfToken;
    if (csrfTokenEl) {
      csrfToken = csrfTokenEl.getAttribute('content');
    } else {
      throw new Error('Could not find `csrf-token` meta element');
    }

    const email = filledInFormData.get('email') as string;
    const fullName = filledInFormData.get('name') as string;
    const companyName = 'COMPANY NAME: ' + filledInFormData.get('companyName');
    const deliveryAddress = 'DELIVERY ADDRESS: ' + filledInFormData.get('deliveryAddress');
    const stateTerritory = 'STATE/TERRITORY: ' + filledInFormData.get('stateTerritory');
    const country = 'COUNTRY SELECTED: ' + countriesList.options[countriesList.selectedIndex].text;
    const phoneNumber = 'PHONE NUMBER: ' + filledInFormData.get('telephoneNumber');
    const asiPpaiAssociated =
      'ASI/PPAI ASSOCIATED: ' + $form.querySelector('input[name="asiPpai"]').checked;
    const certifiedBCorp =
      'CERTIFIED B CORP: ' + $form.querySelector('input[name="certifiedBCorp"]').checked;

    const interestType = 'INTEREST TYPE: ' + filledInFormData.getAll('interestedIn').join(', ');
    const stylesColors = 'PREFERRED STYLES/s AND COLOR/s: ' + filledInFormData.get('stylesColors');
    const quantity = 'DESIRED QUANTITY: ' + filledInFormData.get('quantity');
    const timeframe = 'DELIVERY TIMEFRAME: ' + filledInFormData.get('timeframe');
    const brandingLogo = 'CORPORATE BRANDING OR LOGO: ' + filledInFormData.get('brandingLogo');
    const perItemBudget = 'PER ITEM BUDGET: ' + filledInFormData.get('perItemBudget');

    const subscribeTickValue = $form.querySelector('input[name="newsletterSubscribe"]').checked;

    const formData = new FormData();
    formData.append('email', email);
    formData.append('leave_this_blank', blank);
    formData.append('name', fullName);
    const infoToAppend = [
      companyName,
      deliveryAddress,
      stateTerritory,
      country,
      phoneNumber,
      asiPpaiAssociated,
      certifiedBCorp,
      interestType,
      stylesColors,
      quantity,
      timeframe,
      brandingLogo,
      perItemBudget,
    ];
    formData.append('message', infoToAppend.join('\n\n'));

    http.open('POST', url, true);
    http.setRequestHeader('X-CSRF-Token', csrfToken);

    http.onreadystatechange = function () {
      if (http.readyState === XMLHttpRequest.DONE && http.status === 200) {
        $modal.classList.add('active');
      } else if (http.readyState === XMLHttpRequest.DONE && http.status === 400) {
        const message = JSON.parse(http.responseText).error;
        alert(message);
      }
    };

    http.send(formData);

    if (subscribeTickValue) {
      const subscribeFormData = new FormData();
      const sign_up_source = 'corporate_sales';

      const data: SubscribeData = {
        g: 'MnrA9u',
        $fields: '$consent,$source,$method_type,$method_id,$consent_version,sign_up_source',
        $list_fields: '$consent',
        email: email,
        $source: '$embed',
        $method_type: 'Klaviyo+Form',
        $method_id: 'embed',
        $consent_version: 'Embed+default+text',
        sign_up_source: sign_up_source,
      };

      const dataKeys = Object.getOwnPropertyNames(data);
      for (let i = 0; i < dataKeys.length; i++) {
        const key = dataKeys[i];
        let value;
        if (key) {
          value = data[key];
          subscribeFormData.append(key, value);
        } else {
          throw new Error('Could not find key in data');
        }
      }

      const subscribeUrl = 'https://manage.kmail-lists.com/ajax/subscriptions/subscribe';
      const subscribeHttp = new XMLHttpRequest();
      subscribeHttp.withCredentials = true;
      subscribeHttp.open('POST', subscribeUrl, true);
      subscribeHttp.setRequestHeader('X-CSRF-Token', csrfToken);

      subscribeHttp.onload = () => {
        const subscribeFormDataEvent = new FormData();
        subscribeFormDataEvent.append('member[email]', email);
        subscribeFormDataEvent.append('sign_up_source', sign_up_source);

        const subscribeUrlEvent = '/mailing_lists/klaviyo/members';

        const subscribeHttpEvent = new XMLHttpRequest();
        subscribeHttpEvent.withCredentials = true;

        subscribeHttpEvent.open('POST', subscribeUrlEvent, true);
        subscribeHttpEvent.setRequestHeader('X-CSRF-Token', csrfToken);
        subscribeHttpEvent.send(subscribeFormDataEvent);

        if (typeof window.EventStream != 'undefined') {
          window.EventStream.add({
            eventName: 'newsletter.signup',
            eventData: {
              source: sign_up_source,
            },
          });
        }
      };

      subscribeHttp.send(subscribeFormData);
    }
  });
}
